import React from 'react';
import AccordionListContent from '../AccordionListContent';
import { connect } from 'react-redux';
import { Typography, Box } from '@mui/material';

interface AccordionListProps {
  data: any;
  entries: any[];
}

const AccordionList = (props: AccordionListProps) => {
  const { data, entries } = props;

  const title = entries.find((entry) => entry.sys.id === data).fields.title;
  return (
    <Box sx={{ mb: '15px' }}>
      <Typography
        component="h2"
        sx={{
          fontSize: { lg: '28px', xs: '22px' },
          fontFamily: 'Avenir',
          textTransform: 'uppercase',
        }}
      >
        {title}
      </Typography>
      <AccordionListContent data={data} />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    entries: state.main.entries,
  };
};

export default connect(mapStateToProps, null)(AccordionList);
