import React, {useState} from 'react';
import {useAppSelector} from "../../../redux/store";
import LandingOfferComponent from "./LandingOfferComponent";

const LandingOffer = props => {
  const {data} = props;
  const [entry, setEntry] = useState<any>();
  const entries = useAppSelector(state => state.main.entries);

  React.useEffect(() => {
    setEntry(entries.find((entry) => entry.sys.id === data))
  }, []);

  if (!entry) return null;

  return <LandingOfferComponent title={entry.fields.title}/>;
};

export default LandingOffer;
