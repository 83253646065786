import {Box, Grid, Typography} from "@mui/material";
import ContactButton from "../ContactButton";
import BlackButton from "../../../components/atoms/BlackButton";
import React from "react";

const RowBlock = props => {
  return (
    <Grid container spacing={4} mb={4}>
      <Grid item xs={12} md={6}>
        <Box
          height="400px"
          sx={{
            width: '100%',
            backgroundImage: `url('/images/image 19.png')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          px={2}
          py={4}
          sx={{
            backgroundColor: '#EDF2F7',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '400px',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Avenir',
              fontSize: '24px',
            }}
          >
            KONTAKT
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Lato',
              fontSize: '20px',
              marginTop: '30px',
            }}
          >
            info@pm.se
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Lato',
              fontSize: '20px',
              marginTop: '24px',
              marginBottom: '50px',
            }}
          >
            010-762 62 10
          </Typography>
          <Box sx={{ paddingLeft: { lg: '28px', xs: '13px' } }}>
            <ContactButton title={'Kontakta oss'}></ContactButton>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          px={2}
          id="kontakt"
          py={4}
          sx={{
            height: '400px',
            backgroundColor: '#EDF2F7',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Avenir',
              fontSize: '24px',
            }}
          >
            FAQ
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Lato',
              fontSize: '20px',
              marginTop: '37px',
              marginBottom: '116px',
            }}
          >
            Här hittar du svar på vanliga frågor.
          </Typography>
          <BlackButton
            height="60px"
            width="190px"
            fontSize="17px"
            name="Läs mer"
            link="/vanliga-fragor"
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          height="400px"
          sx={{
            width: '100%',
            backgroundImage: `url('/images/image 16.png')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
      </Grid>
    </Grid>
  );
}

export default RowBlock;
