import React from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { HouseCardProps } from '../../../constants/types';
import numberWithSpaces from '../../../utils/numberWithSpaces';

const HouseCard4ListView = (props: HouseCardProps) => {
  const showings = [...props.showings];
  showings.sort((a, b) => {
    const aDate = new Date(a.TimeStart);
    const bDate = new Date(b.TimeStart);
    if (aDate.getTime() < bDate.getTime()) return -1;
    return 1;
  });
  return (
    <Box
      sx={{ width: '100%', display: 'flex', cursor: 'pointer' }}
      className="zoom-in-wrap"
    >
      <Box
        className="zoom-in"
        sx={{
          height: { lg: '300px', xs: '120px' },
          width: { lg: '340px', xs: '120px' },
          display: 'flex',
          backgroundImage: `url('${props.imagePath}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          flexGrow: 0,
          flexShrink: 0,
          overflow: 'hidden',
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'absolute',
            top: '0',
            right: '0',
            bottom: '0',
            left: '0',
            background: 'inherit',
            transition: 'all 0.3s ease-in-out',
            backgroundSize: 'cover',
            transformOrigin: 'center',
          },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {props.bidding && (
            <Box
              sx={{
                zIndex: 1,
                marginTop: { xs: '6px', lg: '20px' },
                marginLeft: { xs: '6px', lg: '20px' },
                padding: '2px 11px',
                backgroundColor: 'rgb(156, 197, 172)',
                width: { xs: '160px', lg: '180px' },
                height: { xs: '10px', lg: '32px' },
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                component="p"
                className="font-Avenir-Regular"
                sx={{
                  fontSize: { xs: '7px', lg: '14px' },
                  textTransform: 'uppercase',
                }}
              >
                Budgivning pågår
              </Typography>
            </Box>
          )}
          {props.status === 5 && (
            <Box
              sx={{
                zIndex: 1,
                marginTop: { xs: '6px', lg: '20px' },
                marginLeft: { xs: '6px', lg: '20px' },
                padding: '2px 11px',
                color: 'rgb(255, 255, 255)',
                backgroundColor: 'rgb(49, 57, 76)',
                width: { xs: '160px', lg: '180px' },
                height: { xs: '10px', lg: '32px' },
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                component="p"
                className="font-Avenir-Regular"
                sx={{
                  fontSize: { xs: '7px', lg: '14px' },
                  textTransform: 'uppercase',
                }}
              >
                KOMMANDE
              </Typography>
            </Box>
          )}
          {(props.status === 7 || props.status === 9) && (
            <Box
              sx={{
                zIndex: 1,
                marginTop: { xs: '6px', lg: '20px' },
                marginLeft: { xs: '6px', lg: '20px' },
                padding: '2px 11px',
                color: 'rgb(0, 0, 0)',
                backgroundColor: '#bdcad7',
                width: { xs: '160px', lg: '180px' },
                height: { xs: '10px', lg: '32px' },
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                component="p"
                className="font-Avenir-Regular"
                sx={{
                  fontSize: { xs: '7px', lg: '14px' },
                  textTransform: 'uppercase',
                }}
              >
                SÅLD
              </Typography>
            </Box>
          )}
          {showings.length > 0 && (
            <Box
              sx={{
                zIndex: 1,
                marginTop: { xs: '16px', lg: '20px' },
                marginLeft: { xs: '16px', lg: '20px' },
                padding: '2px 11px',
                backgroundColor: '#F5CEB0',
                width: { xs: '160px', md: '180px' },
                height: { xs: '29px', lg: '32px' },
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                component="p"
                className="font-Avenir-Regular"
                sx={{
                  mt: '3px',
                  fontSize: { xs: '12px', lg: '14px' },
                  textTransform: 'uppercase',
                }}
              >
                {`Visning ${
                  new Date(showings[0].TimeStart).getDate() +
                  '/' +
                  (new Date(showings[0].TimeStart).getMonth() + 1)
                }${
                  showings[1]
                    ? ' & ' +
                      new Date(showings[1].TimeStart).getDate() +
                      '/' +
                      (new Date(showings[1].TimeStart).getMonth() + 1)
                    : ''
                }`}
              </Typography>
            </Box>
          )}
          {props.isPrivate && (
            <Box
              sx={{
                zIndex: 1,
                marginTop: { xs: '16px', lg: '20px' },
                marginLeft: { xs: '16px', lg: '20px' },
                padding: '2px 11px',
                backgroundColor: '#FFF0B4',
                width: { xs: '160px', md: '180px' },
                height: { xs: '29px', lg: '32px' },
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                component="p"
                className="font-Avenir-Regular"
                sx={{
                  fontSize: { xs: '12px', lg: '14px' },
                  textTransform: 'uppercase',
                }}
              >
                OFFENTLIG AUKTION
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          paddingX: { lg: '30px', xs: '5px' },
          paddingTop: { lg: '40px', xs: '5px' },
          paddingBottom: { lg: '15px', xs: '0px' },
          backgroundColor: 'rgba(250, 246, 250, 1)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          color: '#2F2E41',
        }}
      >
        <Typography
          component="p"
          className="font-Avenir-Regular"
          sx={{
            fontSize: { lg: '18px', xs: '11px' },
            textTransform: 'uppercase',
            width: 'calc(100%)',
            marginLeft: { xs: '10%', sm: 0 },
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: '1',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
          }}
        >
          {props.name}
        </Typography>
        <Typography
          component="p"
          className="font-Avenir-Regular"
          sx={{
            fontSize: { lg: '30px', xs: '16px' },
            marginTop: { lg: '23px', xs: '5px' },
            textTransform: 'uppercase',
            width: 'calc(100%)',
            marginLeft: { xs: '10%', sm: 0 },
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: '1',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
          }}
        >
          {props.address}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            marginTop: { lg: '85px', xs: '20px' },
            flexWrap: 'wrap',
            justifyContent: { xs: 'space-between', lg: 'start' },
          }}
        >
          {props.roomCount !== null && (
            <Typography
              component="p"
              className="font-Avenir-Regular"
              sx={{
                fontSize: { xs: '10px', lg: '20px' },
                marginRight: { lg: '70px', xs: '10px' },
              }}
            >
              {`${props.roomCount} rum`}
            </Typography>
          )}
          <Typography
            component="p"
            className="font-Avenir-Regular"
            sx={{
              fontSize: { xs: '10px', lg: '20px' },
              marginRight: { lg: '80px', xs: '10px' },
              position: 'relative',
            }}
          >
            {`${props.m2} m`}
            <sup style={{ position: 'absolute', top: '-3px' }}>2</sup>
          </Typography>
          <Typography
            component="p"
            className="font-Avenir-Regular"
            sx={{
              fontSize: { xs: '10px', lg: '20px' },
              marginRight: { lg: '70px', xs: '10px' },
            }}
          >
            {props.type}
          </Typography>
          {props.price > 0 && (
            <Typography
              component="p"
              className="font-Avenir-Bold"
              sx={{
                fontWeight: 800,
                fontSize: { xs: '10px', lg: '20px' },
                marginLeft: { lg: 'auto' },
              }}
            >
              {[2, 5].includes(props.status) &&
                `${numberWithSpaces(props.price)} KR`}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default HouseCard4ListView;
