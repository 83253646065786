import {Box, Grid} from '@mui/material';
import React from 'react';
import GuideCard from "../../atoms/GuideCard";

const LandingSellHome = () => {
  return (
    <>
      <Box
        sx={{
          height: {
            lg: '645px',
            md: "1000px",
            sm: "900px",
            xs: "800px"
          },
          display: "grid",
          gridTemplateColumns: {
            lg: "2fr 1fr"
          },
          gap: "2rem"
      }}
      >
        <Box
          sx={{
            gridRow: {
              lg: "1 / span 2"
            }
          }}
        >
          <Box
            sx={{
              display: {
                lg: "block",
                md: "none",
                sm: "none",
                xs: "none"
              },
              height: "100%"
            }}
          >
            <GuideCard
              title="Välkommen till nya Privatmäklaren"
              buttonTitle="Läs mer"
              buttonWidth="200px"
              buttonHeight="50px"
              fontSize="30px"
              imagePath="/images/image10.jpg"
              link="/valkommen-till-nya-privatmaklaren"
            />
          </Box>
          <Box
            sx={{
              display: {
                lg: "none"
              },
              height: "100%"
            }}
          >
            <GuideCard
              title="Välkommen till nya Privatmäklaren"
              buttonTitle="Läs mer"
              buttonWidth="157px"
              buttonHeight="38px"
              fontSize="17px"
              imagePath="/images/image10.jpg"
              link="/valkommen-till-nya-privatmaklaren"
            />
          </Box>
        </Box>
        <Box>
          <GuideCard
            title="Hur man lägger bud"
            buttonTitle="Läs mer"
            buttonWidth="157px"
            buttonHeight="38px"
            fontSize={"17px"}
            imagePath="/images/image23.jpg"
            link="/kopa/hur-man-lagger-bud"
          />
        </Box>
        <Box>
          <GuideCard
            title="Sälja bostad privat"
            buttonTitle="Guide sälja"
            buttonWidth="157px"
            buttonHeight="38px"
            fontSize={"17px"}
            imagePath="/images/image18.jpg"
            link="/guide/salja-bostad-privat"
          />
        </Box>
      </Box>
    </>
  );
};

export default LandingSellHome;
