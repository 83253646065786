import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';

import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GridViewSharpIcon from '@mui/icons-material/GridViewSharp';

const ViewMode = (props) => {
  const [select, setSelect] = useState(1);

  const handleClick = (id) => {
    setSelect(id);
    props.handleSelect(id);
  };

  return (
    <Box>
      <Box sx={{ border: '2px solid #83B898', display: 'flex' }}>
        <Box
          sx={{
            height: '50px',
            width: '125px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          className={select === 0 && 'viewmode-active' + ' viewmode-item'}
          onClick={() => handleClick(0)}
        >
          <FormatListBulletedIcon />
          <Typography
            sx={{ fontFamily: 'Lato', fontSize: '20px', marginLeft: '5px' }}
          >
            Listvy
          </Typography>
        </Box>
        <Box sx={{ width: '2px', backgroundColor: '#83B898' }} />
        <Box
          sx={{
            height: '50px',
            width: '125px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          className={select === 1 && 'viewmode-active' + ' viewmode-item'}
          onClick={() => handleClick(1)}
        >
          <GridViewSharpIcon />
          <Typography
            sx={{ fontFamily: 'Lato', fontSize: '20px', marginLeft: '5px' }}
          >
            Bildvy
          </Typography>
        </Box>
        <Box sx={{ width: '2px', backgroundColor: '#83B898' }} />
        <Box
          sx={{
            height: '50px',
            width: '125px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          className={select === 2 && 'viewmode-active' + ' viewmode-item'}
          onClick={() => handleClick(2)}
        >
          <LocationOnOutlinedIcon />
          <Typography
            sx={{ fontFamily: 'Lato', fontSize: '20px', marginLeft: '5px' }}
          >
            Karta
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: '383px',
          display: 'flex',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#83B898',
            height: '7px',
            mt: '4px',
            width: '129px',
            marginLeft: select === 0 ? '0px' : select === 1 ? '127px' : '254px',
            transition: 'all 200ms ease-out',
          }}
        ></Box>
      </Box>
    </Box>
  );
};

export default ViewMode;
