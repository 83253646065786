import * as React from 'react';
import { useRouter } from 'next/router';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box } from '@mui/material';
import { houseCardData } from '../../../constants/mockData';
import HouseCard from '../../atoms/HouseCard';
import Link from 'next/link';
import SliderNextButton from '../../atoms/SliderNextButton';
import SliderPrevButton from '../../atoms/SliderPrevButton';
import { connect } from 'react-redux';
import createSlug from '../../../utils/createSlug';
import getObjectImagePath from '../../../utils/getObjectImagePath';
import {AppState} from "../../../redux/store";

const HouseCardSliderXS = ({
  objects,
  municipalities,
  houseTypes,
  biddingInfo,
}) => {
  const router = useRouter();
  const data = [...objects]
    .sort((a, b) => {
      if (a.IsBroker > b.IsBroker) return -1;
      if (a.IsBroker < b.IsBroker) return 1;
      if (a.AddDate > b.AddDate) return -1;
      return 1;
    })
    .filter((i) => i.IsBroker === 1);

  const settings = {
    arrow: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    swipeToSlide: false,
    draggable: false,
    focusOnSelect: false,
    centerPadding: '0px',
    infinite: true,
    fade: false,
    nextArrow: <SliderNextButton />,
    prevArrow: <SliderPrevButton />,
    className: 'react__slick__slider__parent',
  };
  return (
    <Slider {...settings}>
      {data.map((item: any, i: number) => {
        const houseType = createSlug(
          houseTypes.find((type) => type.HouseTypeID === item.HouseTypeId).Type
        );
        const municipality = createSlug(
          municipalities.find(
            (type) => type.MunicipalityID === item.MunicipalityID
          ).Name
        );
        const street = createSlug(item.Address);
        const url = `/${item.Slug}`;

        return (
          <Box p="5px" key={i} onClick={() => router.push(url)}>
            <Link href={url} passHref style={{ textDecoration: 'none'}}>

              <Box sx={{maxWidth: { md: '344px', xs: '280px' }, marginLeft: 'auto', marginRight: 'auto'}}>
                <HouseCard
                  imagePath={getObjectImagePath(item.images, 1, 364, 300)}
                  name={
                    (!!item.AreaName ? item.AreaName + ', ' : '') +
                    municipalities.find(
                      (type) => type.MunicipalityID === item.MunicipalityID
                    ).Name
                  }
                  address={item.Address}
                  price={item.Price}
                  roomCount={item.NumberOfRooms}
                  m2={
                    item.TypeID === 1 && item.HouseTypeId === 11
                      ? item.LotArea
                      : item.Area
                  }
                  type={
                    houseTypes.find((i) => i.HouseTypeID === item.HouseTypeId)
                      .Type
                  }
                  bidding={
                    biddingInfo.find((bid) => bid.id === item.BaseID) !==
                      undefined &&
                    biddingInfo.find((bid) => bid.id === item.BaseID)
                      .biddingOngoing === true
                  }
                  isPrivate={item.IsBroker === 0 ? true : false}
                  showings={item.showings}
                  status={item.Status}
                />
              </Box>

            </Link>
          </Box>
        );
      })}
    </Slider>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    objects: state.main.objects,

    houseAssociationTypes: state.main.houseAssociationTypes,

    municipalities: state.main.municipalities,

    houseTypes: state.main.houseTypes,

    biddingInfo: state.main.biddingInfo,
  };
};

export default connect(mapStateToProps, null)(HouseCardSliderXS);
