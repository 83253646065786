import React, {useState} from 'react';
import { Box, Typography, Container } from '@mui/material';
import HouseCardSliderXS from './HouseCardSliderXS';
import HouseCardSliderLG from './HouseCardSliderLG';
import useWidth from '../../../utils/useWidth';
import {useSelector} from "react-redux";
import {useAppSelector} from "../../../redux/store";

const ResponsiveComponent = () => {
  const width = useWidth();
  return (
    <>
      {width === 'lg' || width === 'xl' ? (
        <HouseCardSliderLG />
      ) : (
        <HouseCardSliderXS />
      )}
    </>
  );
};
const LandingHouseCardSlider = props => {
  const {data} = props;
  const [entry, setEntry] = useState();
  const entries = useAppSelector(state => state.main.entries);

  React.useEffect(() => {
    setEntry(entries.find((entry) => entry.sys.id === data))
  }, []);

  if (!entry) return null;

  return (
    <Container maxWidth="lg">
      <Box
        pt="35px"
        sx={{
          paddingTop: { xs: '35px', md: '58px' },
          width: '100%',
        }}
      >
        <Typography
          component="p"
          sx={{
            fontSize: { xs: '16px', md: '24px' },
            marginBottom: '20px',
            textTransform: 'uppercase',
            fontFamily: 'Avenir-Bold',
            paddingLeft: '20px',
          }}
        >
          {entry.fields.title}
        </Typography>

        <Box sx={{ zIndex: '20', px: 2 }}>
          <ResponsiveComponent />
        </Box>
      </Box>
    </Container>
  );
};

export default LandingHouseCardSlider;
