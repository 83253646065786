import React from 'react';
import { connect } from 'react-redux';
import Image from "next/image";
import { Box } from '@mui/material';
interface MessageComponentProps {
    imagePath: string;
    width: number;
    height: number;
    alt: string;
}

const MediaComponent = (props: MessageComponentProps) => {
    const { imagePath, width, height, alt } = props;

    return (
        <Box
            marginBottom={"1rem"}
        >
            <Image
                src={`https:${imagePath}`}
                layout={"responsive"}
                width={width}
                height={height}
                alt={alt}
            />
        </Box>
    );
};
const mapStateToProps = (state) => {
    return {
        entries: state.main.entries,
    };
};

export default connect(mapStateToProps, null)(MediaComponent);
