import React, { ReactNode } from 'react';
import Head from 'next/head';
import Link from 'next/link';
import CustomerMessage from '../../../contentful/content-types/CustomerMessage';
import Navbar from '../../../contentful/content-types/Navbar';
import Footer from '../../../contentful/content-types/Footer';
import { Box, Container } from '@mui/material';
import HouseInfo from './HouseInfo';
import { connect } from 'react-redux';
import LinearProgress from '@mui/material/LinearProgress';
import createSlug from '../../../utils/createSlug';
import NotFound from "../NotFound";
import {AppState} from "../../../redux/store";

const validation = (slug, houseType, object, houseTypes, municipalities) => {
  if (object === undefined) return 'No data found with this baseID';
  if (
    houseType !==
    createSlug(
      houseTypes.find((type) => type.HouseTypeID === object.HouseTypeId).Type
    )
  )
    return 'House Type is incorrect';
  const slugs = slug.split('-');
  const replacedStreetName = slugs.slice(1, -1).join('-');
  const municipalityName = slugs[0];
  if (
    municipalityName !==
    createSlug(
      municipalities.find(
        (municipality) => municipality.MunicipalityID === object.MunicipalityID
      ).Name
    )
  )
    return 'Municipality Name is incorrect';
  if (createSlug(object.Address) !== replacedStreetName)
    return 'Address name is incorrect';
  return 'success';
};

const ObjectPage = ({
  baseId,
  slug,
  houseType,
  objects,
  municipalities,
  houseTypes,
  objectSlugs,
}) => {
  const objectData = objects.find((object) => object.BaseID == baseId);
  if (!objectSlugs.find((i) => i.search(slug) >= 0))
    return (
        <NotFound/>
    );
  return (
    <>
      <Navbar />
      <HouseInfo data={objectData} />
      <Footer />
    </>
  );
};
const mapStateToProps = (state: AppState) => {
  return {
    objects: state.main.objects,

    houseAssociationTypes: state.main.houseAssociationTypes,

    municipalities: state.main.municipalities,

    houseTypes: state.main.houseTypes,

    objectSlugs: state.main.objectSlugs,
  };
};

export default connect(mapStateToProps, null)(ObjectPage);
