import React, {useState} from 'react';
import { connect } from 'react-redux';
import Image from "next/image";
import { Box } from '@mui/material';
interface YouTubeVideoProps {
    data: string;
    entries: any[];
}

const YouTubeVideo = (props: YouTubeVideoProps) => {
    const { data, entries } = props;
    const [youTubeVideoId, setYouTubeVideoId] = useState(null);

    React.useEffect(() => {
        const res = entries.find((entry) => entry.sys.id === data).fields;
        const youTubeVideoId = res.youTubeVideoId;

        setYouTubeVideoId(youTubeVideoId);
    }, []);

    return (
        <Box
            className={"youTubeVideoOuterContainer"}>
            <Box
                className={"youTubeVideoContainer"}
            >
                <iframe className={"youTubeVideo"} width="560" height="315" src={`https://www.youtube.com/embed/${youTubeVideoId}`}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </Box>
        </Box>
    );
};
const mapStateToProps = (state) => {
    return {
        entries: state.main.entries,
    };
};

export default connect(mapStateToProps, null)(YouTubeVideo);
