import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Zoom from '@mui/material/Zoom';
import Typography from '@mui/material/Typography';
import HouseCard from '../../atoms/HouseCard';
import Link from 'next/link';
import ViewMode from '../../molecules/ViewMode';
import HouseCard4ListView from '../../atoms/HouseCard2';
import dynamic from 'next/dynamic';
import createSlug from '../../../utils/createSlug';
import ContactButton from '../../../contentful/content-types/ContactButton';
import getObjectImagePath from '../../../utils/getObjectImagePath';

const BuyListPageLG = (props) => {
  const { houseTypes, municipalities, biddingInfo } = props;
  let data = [...props.data];
  let soldObjects = [...props.soldObjects];
  const [viewMode, setViewMode] = React.useState(1);
  const MapWithNoSSR = dynamic(() => import('./map'), {
    ssr: false,
  });
  data = data.sort((a, b) => {
    if (a.IsBroker > b.IsBroker) return -1;
    if (a.IsBroker < b.IsBroker) return 1;
    if (a.AddDate > b.AddDate) return -1;
    return 1;
  });

  soldObjects = soldObjects.sort((a, b) => {
    if (a.AddDate > b.AddDate) return -1;
    return 1;
  });
  return (
    <div>
      <Box sx={{ mt: '80px' }}>
        {/* <SearchComponent /> */}
        <Box
          sx={{
            width: '100%',
            height: '320px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: `url('/images/searchBanner.jpg')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <Container maxWidth="lg">
            <Box
              sx={{
                zIndex: '10',
                my: 'auto',
                // width: '632px',
                marginLeft: '16px',
              }}
            >
              <Typography
                component="h1"
                className="font-Avenir-Regular"
                sx={{
                  fontSize: { md: '64px', xs: '32px' },
                  maxWidth: { xs: '330px', md: '600px' },
                  color: 'white',
                  fontWeight: { md: 900, xs: 800 },
                  lineHeight: { md: '76px', xs: '37px' },
                  marginBottom: '16px',
                  textShadow: ' 0px 4px 4px rgba(0, 0, 0, 0.65);',
                }}
              >
                Bostäder till salu
              </Typography>
            </Box>
          </Container>
        </Box>
      </Box>

      <Container>
        <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '15px' }}>
          <ViewMode handleSelect={(id) => setViewMode(id)} />
        </Box>
      </Container>
      {viewMode === 2 ? (
        <Box
          sx={{
            marginTop: '50px',
            marginBottom: '50px',
            position: 'relative',
            height: '90vh',
            zIndex: 0,
          }}
        >
          <MapWithNoSSR
            data={data}
            soldObjects={soldObjects}
            municipalities={municipalities}
          />
        </Box>
      ) : (
        <>
          <Container>
            <Grid container rowSpacing={3} columnSpacing={1} p={5}>
              {(
                data
                  // .sort((a, b) => (a.IsBroker >= b.IsBroker ? -1 : 1))
                  .slice(0, 6) as any
              ).map((item: any, i: number) => {
                const houseType = createSlug(
                  houseTypes.find(
                    (type) => type.HouseTypeID === item.HouseTypeId
                  ).Type
                );
                const municipality = createSlug(
                  municipalities.find(
                    (type) => type.MunicipalityID === item.MunicipalityID
                  ).Name
                );
                const street = createSlug(item.Address);
                const url = `/${item.Slug}`;

                return (
                  <Grid item xs={viewMode === 0 ? 12 : 6} key={i}>
                    <Zoom>
                      <Link href={url} passHref legacyBehavior>
                        <Box
                          p="5px"
                          component="a"
                          sx={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          {viewMode === 1 && (
                            <HouseCard
                              imagePath={getObjectImagePath(
                                item.images,
                                1,
                                532,
                                300
                              )}
                              name={
                                (!!item.AreaName ? item.AreaName + ', ' : '') +
                                municipalities.find(
                                  (type) =>
                                    type.MunicipalityID === item.MunicipalityID
                                ).Name
                              }
                              address={item.Address}
                              price={item.Price}
                              roomCount={item.NumberOfRooms}
                              m2={
                                item.TypeID === 1 && item.HouseTypeId === 11
                                  ? item.LotArea
                                  : item.Area
                              }
                              type={
                                houseTypes.find(
                                  (i) => i.HouseTypeID === item.HouseTypeId
                                ).Type
                              }
                              // bidding={item.Bidding === 1 ? true : false}
                              bidding={
                                biddingInfo.find(
                                  (bid) => bid.id === item.BaseID
                                ) !== undefined &&
                                biddingInfo.find(
                                  (bid) => bid.id === item.BaseID
                                ).biddingOngoing === true
                              }
                              isPrivate={item.IsBroker === 0 ? true : false}
                              showings={item.showings}
                              status={item.Status}
                            />
                          )}
                          {viewMode === 0 && (
                            <HouseCard4ListView
                              imagePath={getObjectImagePath(item.images, 1)}
                              name={
                                (!!item.AreaName ? item.AreaName + ', ' : '') +
                                municipalities.find(
                                  (type) =>
                                    type.MunicipalityID === item.MunicipalityID
                                ).Name
                              }
                              address={item.Address}
                              price={item.Price}
                              roomCount={item.NumberOfRooms}
                              m2={
                                item.TypeID === 1 && item.HouseTypeId === 11
                                  ? item.LotArea
                                  : item.Area
                              }
                              type={
                                houseTypes.find(
                                  (i) => i.HouseTypeID === item.HouseTypeId
                                ).Type
                              }
                              bidding={
                                biddingInfo.find(
                                  (bid) => bid.id === item.BaseID
                                ) !== undefined &&
                                biddingInfo.find(
                                  (bid) => bid.id === item.BaseID
                                ).biddingOngoing === true
                              }
                              isPrivate={item.IsBroker === 0 ? true : false}
                              showings={item.showings}
                              status={item.Status}
                            />
                          )}
                        </Box>
                      </Link>
                    </Zoom>
                  </Grid>
                );
              })}
            </Grid>

            <Box
              mx={5}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',

                height: { lg: '394px', xs: '180px' },
                backgroundColor: {
                  xs: 'rgba(131, 184, 152, 1)',
                  lg: 'rgba(49, 57, 76, 1)',
                },
              }}
            >
              <Typography
                component="p"
                className="font-Lato-Bold"
                sx={{
                  fontSize: { xs: '16px', lg: '36px' },
                  color: { lg: 'white', xs: 'black' },
                }}
              >
                Funderar du på att sälja din bostad?
              </Typography>
              <Typography
                component="p"
                className="font-Lato-Regular"
                sx={{
                  fontSize: { xs: '12px', lg: '24px' },
                  marginTop: { lg: '55px', xs: '9px' },
                  marginBottom: { lg: '72px', xs: '22px' },
                  color: { lg: 'white', xs: 'black' },
                }}
              >
                Låt oss kontakta dig för fri rådgivning.
              </Typography>
              <ContactButton title={'Kontakt'}></ContactButton>
            </Box>

            <Grid container rowSpacing={3} columnSpacing={1} p={5}>
              {(
                data
                  // .sort((a, b) => (a.IsBroker >= b.IsBroker ? -1 : 1))

                  .slice(6, data.length) as any
              ).map((item: any, i: number) => {
                const houseType = createSlug(
                  houseTypes.find(
                    (type) => type.HouseTypeID === item.HouseTypeId
                  ).Type
                );
                const municipality = createSlug(
                  municipalities.find(
                    (type) => type.MunicipalityID === item.MunicipalityID
                  ).Name
                );
                const street = createSlug(item.Address);
                const url = `/${item.Slug}`;

                return (
                  <Grid item xs={viewMode === 0 ? 12 : 6} key={i}>
                    <Zoom>
                      <Link href={url} passHref legacyBehavior>
                        <Box
                          p="5px"
                          component="a"
                          sx={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          {viewMode === 1 && (
                            <HouseCard
                              imagePath={getObjectImagePath(item.images, 1, 532, 300)}
                              name={
                                (!!item.AreaName ? item.AreaName + ', ' : '') +
                                municipalities.find(
                                  (type) =>
                                    type.MunicipalityID === item.MunicipalityID
                                ).Name
                              }
                              address={item.Address}
                              price={item.Price}
                              roomCount={item.NumberOfRooms}
                              m2={
                                item.TypeID === 1 && item.HouseTypeId === 11
                                  ? item.LotArea
                                  : item.Area
                              }
                              type={
                                houseTypes.find(
                                  (i) => i.HouseTypeID === item.HouseTypeId
                                ).Type
                              }
                              bidding={
                                biddingInfo.find(
                                  (bid) => bid.id === item.BaseID
                                ) !== undefined &&
                                biddingInfo.find(
                                  (bid) => bid.id === item.BaseID
                                ).biddingOngoing === true
                              }
                              isPrivate={item.IsBroker === 0 ? true : false}
                              showings={item.showings}
                              status={item.Status}
                            />
                          )}
                          {viewMode === 0 && (
                            <HouseCard4ListView
                              imagePath={getObjectImagePath(item.images, 1)}
                              name={
                                (!!item.AreaName ? item.AreaName + ', ' : '') +
                                municipalities.find(
                                  (type) =>
                                    type.MunicipalityID === item.MunicipalityID
                                ).Name
                              }
                              address={item.Address}
                              price={item.Price}
                              roomCount={item.NumberOfRooms}
                              m2={
                                item.TypeID === 1 && item.HouseTypeId === 11
                                  ? item.LotArea
                                  : item.Area
                              }
                              type={
                                houseTypes.find(
                                  (i) => i.HouseTypeID === item.HouseTypeId
                                ).Type
                              }
                              bidding={
                                biddingInfo.find(
                                  (bid) => bid.id === item.BaseID
                                ) !== undefined &&
                                biddingInfo.find(
                                  (bid) => bid.id === item.BaseID
                                ).biddingOngoing === true
                              }
                              isPrivate={item.IsBroker === 0 ? true : false}
                              showings={item.showings}
                              status={item.Status}
                            />
                          )}
                        </Box>
                      </Link>
                    </Zoom>
                  </Grid>
                );
              })}

              {(
                soldObjects
                  // .sort((a, b) => (a.IsBroker >= b.IsBroker ? -1 : 1))

                  .slice(0, 20) as any
              ).map((item: any, i: number) => {
                const houseType = createSlug(
                  houseTypes.find(
                    (type) => type.HouseTypeID === item.HouseTypeId
                  ).Type
                );
                const municipality = createSlug(
                  municipalities.find(
                    (type) => type.MunicipalityID === item.MunicipalityID
                  ).Name
                );
                const street = createSlug(item.Address);
                const url = `/${item.Slug}`;
                return (
                  <Grid item xs={viewMode === 0 ? 12 : 6} key={i}>
                    <Zoom>
                      <Link href={url} passHref legacyBehavior>
                        <Box
                          p="5px"
                          component="a"
                          sx={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          {viewMode === 1 && (
                            <HouseCard
                              imagePath={getObjectImagePath(item.images, 1, 532, 300)}
                              name={
                                (!!item.AreaName ? item.AreaName + ', ' : '') +
                                municipalities.find(
                                  (type) =>
                                    type.MunicipalityID === item.MunicipalityID
                                ).Name
                              }
                              address={item.Address}
                              price={item.Price}
                              roomCount={item.NumberOfRooms}
                              m2={
                                item.TypeID === 1 && item.HouseTypeId === 11
                                  ? item.LotArea
                                  : item.Area
                              }
                              type={
                                houseTypes.find(
                                  (i) => i.HouseTypeID === item.HouseTypeId
                                ).Type
                              }
                              bidding={
                                biddingInfo.find(
                                  (bid) => bid.id === item.BaseID
                                ) !== undefined &&
                                biddingInfo.find(
                                  (bid) => bid.id === item.BaseID
                                ).biddingOngoing === true
                              }
                              isPrivate={item.IsBroker === 0 ? true : false}
                              showings={item.showings}
                              status={item.Status}
                            />
                          )}
                          {viewMode === 0 && (
                            <HouseCard4ListView
                              imagePath={getObjectImagePath(item.images, 1)}
                              name={
                                (!!item.AreaName ? item.AreaName + ', ' : '') +
                                municipalities.find(
                                  (type) =>
                                    type.MunicipalityID === item.MunicipalityID
                                ).Name
                              }
                              address={item.Address}
                              price={item.Price}
                              roomCount={item.NumberOfRooms}
                              m2={
                                item.TypeID === 1 && item.HouseTypeId === 11
                                  ? item.LotArea
                                  : item.Area
                              }
                              type={
                                houseTypes.find(
                                  (i) => i.HouseTypeID === item.HouseTypeId
                                ).Type
                              }
                              bidding={
                                biddingInfo.find(
                                  (bid) => bid.id === item.BaseID
                                ) !== undefined &&
                                biddingInfo.find(
                                  (bid) => bid.id === item.BaseID
                                ).biddingOngoing === true
                              }
                              isPrivate={item.IsBroker === 0 ? true : false}
                              showings={item.showings}
                              status={item.Status}
                            />
                          )}
                        </Box>
                      </Link>
                    </Zoom>
                  </Grid>
                );
              })}
            </Grid>
          </Container>
        </>
      )}
    </div>
  );
};

export default BuyListPageLG;
