import React from 'react';

import Text from '../Text';
import { connect } from 'react-redux';
import Accordion from '../../../components/molecules/Accordion';

const ComponentListTypes = {
  ACCORDION_LIST_ITEM: 'accordionListItem',
};

interface AccordionListContentProps {
  data: string;
  entries?: any[];
}

interface ListItemProps {
  data: any;
}

const ListItem = (props: ListItemProps) => {
  const { data } = props;
  const contentTypeId = data.sys.contentType.sys.id;
  const Component = ListTypeMap[contentTypeId];

  if (!Component) {
    console.warn(`${contentTypeId} can not be handled`);
    return (
      <div
        className=""
        style={{ border: '1px solid', margin: '3px', padding: '3px' }}
      >
        This component - "{contentTypeId}" can't be handled yet.
      </div>
    );
  }

  const { id } = data.sys;

  const componentProps = {
    data: id,
    isAccordion: true,
  };

  return <Component key={`${contentTypeId}-${id}`} {...componentProps} />;
};

const AccordionListContent = (props: AccordionListContentProps) => {
  const { data, entries } = props;

  const list = entries.find((entry) => entry.sys.id === data).fields.items;
  const accordionData = list.map((item, i) => {
    const listItemData = entries.find((entry) => entry.sys.id === item.sys.id);
    return {
      name: listItemData.fields.title,
      content: (
        <>
          <ListItem key={i} data={listItemData} />
        </>
      ),
    };
  });

  return <Accordion data={accordionData} />;
};

const ListTypeMap = {
  [ComponentListTypes.ACCORDION_LIST_ITEM]: Text,
};

const mapStateToProps = (state) => {
  return {
    entries: state.main.entries,
  };
};

export default connect(mapStateToProps, null)(AccordionListContent);
