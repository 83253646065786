import React from 'react';
// import Box from '@mui/material/Box';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
// import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { GuideCardProps } from '../../../constants/types';
import Link from 'next/link';

const GuideCard = (props: GuideCardProps) => {
  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Box
        sx={{
          height: '66%',
          width: '100%',
          display: 'flex',
          backgroundImage: `url('${props.imagePath}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <Box
        sx={{
          width: '100%',
          height: '34%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundColor: 'rgba(189, 202, 215, 1)',
        }}
      >
        <Typography
          component="p"
          className="font-Avenir-Regular"
          sx={{ fontSize: props.fontSize }}
        >
          {props.title}
        </Typography>
        <Link href={props.link} passHref style={{ textDecoration: 'none' }}>

          <Button
            color="info"
            variant="contained"
            sx={{
              textTransform: 'none',
              fontSize: '14px',
              width: props.buttonWidth,
              height: props.buttonHeight,
              borderRadius: '0px',
              backgroundColor: 'rgba(49, 57, 76, 1)',
              color: 'white',
              mt: '10px',
            }}
          >
            {props.buttonTitle}
          </Button>

        </Link>
      </Box>
    </Box>
  );
};

export default GuideCard;
