import React from 'react';
import { Button as MUIButton, Typography, Box } from '@mui/material';
import Link from '../Link';
import { connect } from 'react-redux';
import CustomerReviewCard from '../../../components/atoms/CustomerReviewCard';

const Testimonials = (props) => {
  const [reviewCardData, setReviewCardData] = React.useState(null);

  React.useEffect(() => {
    const data = props.entries.filter(
      (entry) => entry.sys.contentType.sys.id === 'testimonial'
    );

    setReviewCardData(
      data.map((item) => {
        return {
          author: item.fields.customerName,
          content: item.fields.text,
          imagePath: item.fields.image.fields.file.url,
          title: item.fields.title,
        };
      })
    );
  }, []);

  return (
    <>
      <Box sx={{ width: '100%', position: 'relative' }}>
        {((reviewCardData as any) ?? []).map((item: any, i: number) => (
          <Box py="15px" key={i}>
            <CustomerReviewCard {...item} />
          </Box>
        ))}
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    entries: state.main.entries,
  };
};

export default connect(mapStateToProps, null)(Testimonials);
