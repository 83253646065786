import { Box, Typography } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import ContactForm from '../../../components/molecules/ContactForm';
import ValuationForm from '../../../components/molecules/ValuationForm';

interface FormProps {
  data: string;
  entries: any[];
}

const Form = (props: FormProps) => {
  const { data, entries } = props;
  const [formType, setFormType] = React.useState(null);

  React.useEffect(() => {
    const res = entries.find((entry) => entry.sys.id === data).fields;
    setFormType(res.type);
  }, []);
  return (
    <>
      <Box
        sx={{
          maxWidth: '840px',
          // marginTop: '50px'
          margin: '50px auto',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Avenir-Bold',
            fontSize: '24px',
            textTransform: 'uppercase',
          }}
        >
          {formType === 'contact' ? 'Kontakt' : 'Gratis värdering'}
        </Typography>
        {formType === 'contact' && (
          <ContactForm />
        )}
        {formType === 'valuation' && (
          <ValuationForm />
        )}
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    entries: state.main.entries,
  };
};

export default connect(mapStateToProps, null)(Form);
